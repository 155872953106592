import { useEffect, useState } from 'react';
import { Section } from '../section';

const HomeSection = ({ currentSection }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let timer;
    if (currentSection === 0) { 
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 1200);
    } else {
      setIsVisible(false);
      setIsLoaded(false);
    }
    return () => clearTimeout(timer);
  }, [currentSection]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 100); 
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Section id="home__section">
      {/* <div className="home__section"> */}
        <div className={`home__section ${isLoaded ? 'loaded' : ''} ${isVisible ? 'visible' : ''}`}>
          <div className="home-main-text">HI I AM_</div>
          <div className="home-submain-text-wrapper">
            <div className="glitch" data-text="ALEX ANACKIS">ALEX ANACKIS</div>
          </div>
          <div className="home-main-text">I AM_</div>
          <div className="home-submain-text-wrapper">
            <div className="glitch" data-text="WEB DEVELOPER">WEB DEVELOPER</div>
          </div>
        </div>
      {/* </div> */}
    </Section>
  )
}

export default HomeSection;
