import React, { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Scroll, ScrollControls } from "@react-three/drei";
import { MotionConfig } from "framer-motion";
import { framerMotionConfig } from "./assets/config";
import { ScrollManager } from "./components/ScrollManager";
import { Scene } from "./components/Scene";
import { Interface } from "./components/Interface";
import { Menu } from "./components/page-components/menu/menu";
import { useSection } from './contexts/SectionContext';
import { Navbar } from './components/page-components/navbar/navbar';
import LoadingScreen from "./components/page-components/loading/loading";
import { SidePanel } from "./components/page-components/side-panel/side-panel";

const CV = React.lazy(() => import('./components/page-components/cv/cv'));
const ContactContent = React.lazy(() => import('./components/page-components/contact-content/contact-content'));
const ProjectsModal = React.lazy(() => import('./components/page-components/projects-modal/projects-modal'));
const ImgFullScreenSlider = React.lazy(() => import('./components/page-components/img-full-screen-slider/img-full-screen-slider'));

function App() {
  const [isLoading, setLoading] = useState(true);
  const { section } = useSection();
  const [isConnectModalOpen, setConnectModalOpen] = useState(false);
  const [cvModalOpen, setCvModalOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [imagesForSlider, setImagesForSlider] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3700);
    return () => clearTimeout(timer);
  }, []);

  const openProjectModal = (project) => {
    setSelectedProject(project); 
    setProjectModalOpen(true);
  };

  const closeProjectModal = () => {
    setProjectModalOpen(false); 
  };

  const openImgSlider = (images) => {
    setImagesForSlider(images);
    setFullscreenOpen(true);
  };

  const closeImgSlider = () => {
    setFullscreenOpen(false); 
  };

  return (
    <div className={`main ${section === 0 ? 'main-section0' : section === 1 ? 'main-section1' : ''}`}>

      {isLoading && (
        <LoadingScreen />
      )}

      {!isLoading && (
        <MotionConfig transition={{ ...framerMotionConfig }}>
          <Canvas 
            // pixelratio={Math.min(0.000000000000001, window.devicePixelRatio * 0.000000000000001)}  // pixelratio={isHighResolution ? 0.25 : window.devicePixelRatio}
            shadows={false} 
            camera={{ position: [0, 2, 5], fov: 30 }}
          >
            
            <Scene menuOpened={menuOpened}/>

            <ScrollControls pages={5} damping={0.1}>
              <ScrollManager/>
                <Scroll html>
                  <Interface 
                    currentSection={section} 
                    openProjectModal={openProjectModal} 
                  />
                </Scroll>
            </ScrollControls>
          </Canvas>
        </MotionConfig>
      )}

      <Navbar 
        menuOpened={menuOpened} 
        setConnectModalOpen={setConnectModalOpen} 
        setCvModalOpen={setCvModalOpen}
      />

      <Menu 
        menuOpened={menuOpened} 
        setMenuOpened={setMenuOpened} 
        setCvModalOpen={setCvModalOpen}
      />
      
      <SidePanel/>

      <React.Suspense fallback={<div>Loading...</div>}>
        {isConnectModalOpen && (
          <ContactContent closeModal={() => setConnectModalOpen(false)} />
        )}
     
        {cvModalOpen && (
          <CV setCvModalOpen={setCvModalOpen}/>
        )}
     
        {projectModalOpen && (
          <ProjectsModal 
            project={selectedProject} 
            onClose={closeProjectModal} 
            openImgSlider={openImgSlider} 
          />
        )}
      
        {fullscreenOpen && (
          <ImgFullScreenSlider
            images={imagesForSlider}
            onClose={closeImgSlider}
          />
        )}
      </React.Suspense>

    </div>
  );
}

export default App;
