import { Avatar } from "./Avatar";
import { Room } from "./Room";
import { motion } from "framer-motion-3d";
import { Environment } from "@react-three/drei";
import { animate, useMotionValue } from "framer-motion";
import { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { framerMotionConfig } from "../assets/config";

import JsIconCube from "./Prisms";
import EarthModel from './EarthModel';

import ReactIcon from '../assets/icons/react.webp';
import GitIcon from '../assets/icons/git.svg';
import TsIcon from '../assets/icons/ts.webp';
import AngularIcon from '../assets/icons/angular.svg';
import NestJsIcon from '../assets/icons/nestjs.svg';
import RxJsIcon from '../assets/icons/rxjs.svg';
import MySqlIcon from '../assets/icons/mysql.svg';
import ApiIcon from '../assets/icons/api.svg';
import KuberIcon from '../assets/icons/kubernetes.svg';

import { useSection } from "../contexts/SectionContext";
import { useUI } from "../contexts/UIContext ";


export const Scene = ({ menuOpened }) => {
  const images = [AngularIcon, NestJsIcon, RxJsIcon, TsIcon, ReactIcon, GitIcon, MySqlIcon, ApiIcon, KuberIcon];
  const cameraPositionX = useMotionValue(0);
  const cameraLookAtX = useMotionValue(0); 
  const { section } = useSection();
  const { screenWidth } = useUI();

  useEffect(() => {
    animate(cameraPositionX, menuOpened ? -3 : 0, {
      ...framerMotionConfig,
    });
    animate(cameraLookAtX, menuOpened ? 0 : 0, {
      ...framerMotionConfig,
    });
  }, [menuOpened]);

  // Menu open 3D objects animation
  useFrame((state) => {
    state.camera.position.x = cameraPositionX.get();
    state.camera.lookAt(cameraLookAtX.get(), 0, 0);
  });

  let jsIconsPositions = [
    [0.1, 0.8, 0],
    [1, 0.8, 0],
    [1.9, 0.8, 0],

    [0.1, -0.10, 0.1],
    [1, -0.10, 0.1],
    [1.92, -0.10, 0.1],

    [0.1, -0.95, 0.3],
    [1.02, -0.95, 0.3],
    [1.95, -0.95, 0.3],
  ];
  
  let motionGroupRotation = [-0.2, Math.PI / -5, 0]

  let avatarPosX = 0.2; 
  let avatarPosY = section === 0 ? -0.1 : 0.3;
  let avatarPosZ = -0.5;
  let avatarScale = section === 0 ? 1 : 0.55; 

  let roomPosX = 0.2; 
  let roomPosY = 0.3;
  let roomPosZ = -0.5;
  let roomScale = 1.2; 

  let jsIconsPosX = 0.4;
  let jsIconsScale = 0.9;
  
  let earthPositionX = 1.5;
  let earthPosX = 0;
  let earthPosY = 0;
  let earthScale = 0.8;

  if (screenWidth < 2000) {
    earthPositionX = 1.8

  } if (screenWidth < 1700) {
    jsIconsScale = 0.8;

  } if (screenWidth < 1450) {
   
    if (section === 0) {
      avatarPosX = -0.5;
    } 

  } if (screenWidth < 1200) {
    jsIconsScale = 0.55;
    jsIconsPosX = 0.7;
  } if (screenWidth < 991) {
    motionGroupRotation = [0, -0.2, -0.1]

    if (section === 0) {
      avatarPosX = -0.5;
    } else {
      avatarPosX = -0.2;
      roomPosX = 0; 
    }

    jsIconsPosX = -0.5;
    jsIconsScale = 0.5;
    jsIconsPositions = [
      [-0.5, 1.2, 0],
      [0.5, 1.2, 0],
      [1.5, 1.2, 0],
      [2.5, 1.2, 0],

      [-1, 2.1, 0],
      [0, 2.1, 0],
      [1, 2.1, 0],
      [2, 2.1, 0],
      [3, 2.1, 0],
    ];

    earthPosX = -2.8;
    earthPosY = 1.5;
    earthScale = 0.6;
    
  } if (screenWidth < 650) {
    if (section === 0) {
     
      avatarPosX = -1.1;
      avatarPosY = -0.8;
      avatarPosZ = -0.2;
      avatarScale = 0.8;
    } else {
      avatarPosX = -1;
      avatarPosY = -1.7;
      avatarPosZ = -0.2;
      avatarScale = 0.8;
  
      roomPosX = -1; 
      roomPosY = -1.7;
      roomPosZ = -0.5;
      roomScale = 2.1; 

      jsIconsScale = 0.35;
      jsIconsPosX = -0.4;
      jsIconsPositions = [
        [-1, 2.4, 0],
        [0, 2.4, 0],
        [1, 2.4, 0],
        [2, 2.4, 0],
  
        [-0.5, 3.35, 0],
        [0.5, 3.35, 0],
        [1.5, 3.35, 0],
        [2.5, 3.35, 0],
        [3, 2.4, 0],
      ];
    }

    earthPosX = -3.55;
    earthPosY = 2.1;
    earthScale = 0.5;
    
  } if (screenWidth < 400) {
    jsIconsPositions = [
      [-0.5, 2.4, 0],
      [0.3, 2.4, 0],
      [1.1, 2.4, 0],
      [1.9, 2.4, 0],

      [-0.3, 3.35, 0],
      [0.5, 3.35, 0],
      [1.3, 3.35, 0],
      [2.1, 3.35, 0],
      [2.7, 2.4, 0],
    ];
    if (section === 0) {
      avatarPosY = -0.6;
    } else {
      avatarPosY = -1.5;
    }
  } if (screenWidth > 1500) {
    avatarPosX = 0;
  }

  return (
    <>
      {/* {( section === 0 || section === 1 || section === 2 ) && ( */}
        <motion.group
          // visible={section === 0 || section === 1 || section === 2}
          // rotation={[-0.2, Math.PI / -5, 0]}
          rotation={motionGroupRotation}
          animate={{
            y: section === 0 ? -1.1 : -0.9,
            x: (section === 2 || section === 3 || section === 4 || section === 5) ? 10 : 1,
          }}
          transition={{ duration: 2 }}
          position-y={-2}
          position-x={5}
        >        

          {/* {( section === 0 || section === 1 || section === 2 ) && ( */}
            <Avatar
              visible={section === 0 || section === 1 || section === 2}
              rotation={section === 0 ? [0, 0, 0] : [0.2, 0, 0]}
              animation={section === 0 ? "Falling" : "Hello"}
              position-x={avatarPosX}
              position-y={avatarPosY}
              position-z={avatarPosZ}
              scale={avatarScale}
            />
          {/* )} */}

          {/* {(section === 1 || section === 2 ) && ( */}
            <Room 
              visible={section === 1 || section === 2}
              rotation={[0.1, 0.7, 0]} 
              position-x={roomPosX}  
              position-y={roomPosY} 
              position-z={roomPosZ}
              scale={roomScale}
              section={section}
            />
          {/* )} */}
        </motion.group>
      {/* )} */}

    
      <motion.group
        // rotation={[-0.2, Math.PI / -6, 0]}
        visible={section === 1 || section === 2 || section === 3}
        animate={{
          // y: section === 2 ? 0 : -10,
          // x: (section === 2 || section === 3 || section === 4) ? 10 : 1,
          y: section === 2 || section === 3 ? 0 : -10,  
          x: section === 3 ? 10 : (section === 1 || section === 2) ? jsIconsPosX : 0,
        }}
        transition={{ duration: 2 }}
        // position-x={jsIconsPosX}
        // position-y={-10}
        scale={jsIconsScale}
      >
        {
          jsIconsPositions.map((position, index) => (
            <JsIconCube 
              visible={section === 1 || section === 2 || section === 3}
              key={index} 
              position={position} 
              img={images[index]} 
              modelPath="/models/prism.glb"
            />
          ))
        }
      </motion.group>
      

      <motion.group
        visible={section === 3 || section === 4}
        animate={{
          x: (section === 4) ? earthPositionX : 11,
        }}
        transition={{ duration: 2 }}
        position-x={2}
        scale={earthScale}
      >
        <EarthModel
          visible={section === 3 || section === 4}
          position={[earthPosX, earthPosY, 0]}   
        /> 
      </motion.group>
      

      <Environment preset="sunset" />
    </>
  );
};





// const { animation } = useControls({
//   animation: {
//     value: "Falling",
//     options: ["Falling", "Hello"],
//   },
// });

// {/* <Avatar animation={animation}/> */}
// {/* <OrbitControls />
// <Environment preset="sunset"/> */}
// {/* <Room /> */}
// {/* <ambientLight intensity={1} /> */}