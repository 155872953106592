import ReactDOM from 'react-dom/client';
import './index.scss';
import './responsive.scss';
import './components/page-components/section.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { SectionProvider } from './contexts/SectionContext';
import App from './App';
import { UIProvider } from './contexts/UIContext ';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UIProvider>
    <SectionProvider>
      <App/>
    </SectionProvider>
  </UIProvider>
);
