import { useEffect, useState } from 'react';
import { Section } from '../section';

const AboutSection = ({ currentSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(currentSection === 1);
  }, [currentSection]);

  return (
    <Section id="about__section">
      <div className={`about block ${isVisible ? 'about-visible' : 'about-not-visible'}`}>
        <div className="section-about-wrapper">
          <div className="section__header">
            <div className="section__header_text fz-header">About me</div>
          </div>
          <div className="bubble_wrap">
            <p className='fz-text scrollable-text'>

              <span className='block_sub-title'>Personal</span>
              <span className='block_sub-text'>
                I am 25 years old ambitious and energetic Full Stack Web Developer. 
                I have over 2.5 years of experience in web development, including 1.5 years as a full stack developer in a fintech company and 1 year learning web development through courses and other sources.
                My main object is to evolve my hard and soft skills during delivering the best profit and performance for the company I can.
              </span>

              <span className='block_sub-title'>Work Experience</span>
              <span className='block_sub-text'>
                During my work history, I gained good soft skills having experience working individually, in a team and being a team lead too. I worked in different work settings as an onsite, hybrid, and remote. My favorite is to work hybrid to combine the benefits of both worlds. Was working mainly with Linux (Ubuntu) system and had experience with Windows too. So far I have not had experience with macOS.
              </span>
             
              <span className='block_sub-title'>Extra Information</span>
              <span className='block_sub-text'>
                While my early interests included playing the guitar, karate, and skateboarding, these days I primarily focus on fitness, maintaining a healthy lifestyle, and socializing with friends. Sometimes I play computer games too, My favorite ones are World Of Warcraft, Call Of Dutty and Valorant. I like cyberpunk thematic, Marvell films. My favorite film is Bladerunner and the series is Cyberpunk Edgerunner. 
              </span>

              <span className='block_sub-title'>Languages</span>
              <span className='block_sub-text'>
                I speak for now 3 languages: English, Russian and Latvian. And have a desire to keep evolving them all and use them during the work process.
              </span>
            
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AboutSection