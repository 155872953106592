import { createContext, useState, useContext, useEffect } from 'react';

const UIContext = createContext({
  screenWidth: window.innerWidth,
});

export const useUI = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const value = {
    screenWidth,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
