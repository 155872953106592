import { useState, useEffect } from 'react';
import { useSection } from '../../../contexts/SectionContext'; 
import socialGithub from '../../../assets/socialIcons/github.svg';
import socialLinkedIn from '../../../assets/socialIcons/linkedin.svg';
import socialInstagram from '../../../assets/socialIcons/instagram.svg';

export const SidePanel = () => {
  const { section } = useSection();
  const [isVisible, setIsVisible] = useState(false);
  const [displayStyle, setDisplayStyle] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (section === 3) {
      setIsVisible(false);
      const hideTimer = setTimeout(() => {
        setDisplayStyle({ display: 'none' });
      }, 2000); 
      return () => clearTimeout(hideTimer);
    } else {
      setDisplayStyle({});
      const delay = initialLoad ? 2000 : 500; 
      const showTimer = setTimeout(() => {
        setIsVisible(true);
        setInitialLoad(false); 
      }, delay);

      return () => clearTimeout(showTimer);
    }
  }, [section, initialLoad]); 

	return (
    <aside className={isVisible ? 'show' : ''} style={displayStyle}>
      <div className="sidepanel">
        <div className="sidepanel__text title title_fz16"><span>Social networks</span></div>
        <div className="sidepanel__line"></div>
        
        <a href="https://www.instagram.com/alex_anackis_/" target="_blank" rel="noopener noreferrer"><img src={socialInstagram} alt="instagram-icon" /></a>
        <a href="https://www.linkedin.com/in/aleksandrs-anackis-9831371a8/" target="_blank" rel="noopener noreferrer"><img src={socialLinkedIn} alt="linkedIn-con" /></a>
        <a href="https://github.com/anackis" target="_blank" rel="noopener noreferrer"><img src={socialGithub} alt="github-icon"/></a>
      </div>
    </aside>
	)
}