import { useRef, useState, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useSection } from "../contexts/SectionContext";
import { useUI } from '../contexts/UIContext ';

function EarthModel({ position }) {
  const { scene } = useGLTF('models/earth.glb');  
  const clonedScene = useMemo(() => scene.clone(), [scene]);
  const groupRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [rotationX, setRotationX] = useState(0); 
  const [rotationY, setRotationY] = useState(0); 
  const { section } = useSection();
  const { screenWidth } = useUI();
  
  const disableTextSelection = () => {
    document.body.style.userSelect = 'none';
    document.body.style.pointerEvents = 'none';
  };

  const enableTextSelection = () => {
    document.body.style.userSelect = '';
    document.body.style.pointerEvents = '';
  };

  const onPointerOver = () => {
    if (screenWidth >= 1700) {
      document.body.style.cursor = 'grab';
    }
  };

  const onPointerDown = (e) => {
    if (screenWidth >= 1700) {
      setIsDragging(true);
      setStartX(e.clientX);
      setStartY(e.clientY);
      disableTextSelection();
    }
  };

  const onPointerUp = () => {
    if (screenWidth >= 1700) {
      setIsDragging(false);
      enableTextSelection();
      document.body.style.cursor = 'grab';
    }
  };

  const onPointerOut = () => {
    if (screenWidth >= 1700) {
      document.body.style.cursor = 'auto';
      setIsDragging(false);
      enableTextSelection();
    }
  };

  const onPointerMove = (e) => {
    if (isDragging && screenWidth >= 1700) {
      document.body.style.cursor = 'grabbing';

      const dx = (e.clientX - startX) * 0.015;
      const dy = (e.clientY - startY) * 0.015;

      setRotationX(rotationX + dy);
      setRotationY(rotationY + dx);

      setStartX(e.clientX);
      setStartY(e.clientY);
    }
  };

  useFrame(() => {
    if (section === 4) {
      if (!isDragging) {
        groupRef.current.rotation.y += -0.005;
      } else {
        groupRef.current.rotation.x = rotationX;
        groupRef.current.rotation.y = rotationY;
      }
    }
  });

  return (
    <group 
      ref={groupRef}
      position={position}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <primitive object={clonedScene} />
    </group>
  );
}

export default EarthModel;
