import { useEffect, useState } from "react";
import { useSection } from "../../../contexts/SectionContext";
import socialGithub from '../../../assets/socialIcons/github.svg';
import socialLinkedIn from '../../../assets/socialIcons/linkedin.svg';
import socialInstagram from '../../../assets/socialIcons/instagram.svg';
import cvIcon from '../../../assets/socialIcons/cv-icon.svg';

const useDelayedVisibility = (isVisible, delay) => {
  const [isVisibleDelayed, setIsVisibleDelayed ] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        setIsVisibleDelayed(true);
      }, delay);
    } else {
      clearTimeout(timeoutId);
      setIsVisibleDelayed(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible, delay]);

  return isVisibleDelayed;
};

export const Menu = ({ menuOpened, setMenuOpened, setCvModalOpen }) => {
  const { section, setSection } = useSection();
  const delayedMenuOpen = useDelayedVisibility(menuOpened, 500);

  useEffect(() => {
    if (menuOpened) {
      setMenuOpened(false); 
    }
  }, [section]);

  return(
    <>
      <button
        className="hamburger"
        onClick={() => setMenuOpened(!menuOpened)}
      >
        <div className={`hamburger__line ${menuOpened ? "hamburger__rotate-45 hamburger__line_white" : ""}`}></div>
        <div className={`hamburger__line ${menuOpened ? "hidden" : ""}`}></div>
        <div className={`hamburger__line ${menuOpened ? "hamburger__rotate-minus-45 hamburger__line_white" : ""}`}></div>
      </button>
      <div className={`navbar ${menuOpened ? "" : "navbar__hidden"}`}>
        <div className="navbar__wrapper">
          {["HOME", "ABOUT", "SKILLS", "PROJECTS", "CONTACTS"].map((label, index) => (
            <MenuButton
              key={index} 
              label={label}
              onClick={() => setSection(index)}
              isVisible={delayedMenuOpen}
              isActive={section === index}
            />
          ))}
          <div className="navbar__wrapper_links">
            <a href="https://github.com/anackis" target="_blank" rel="noopener noreferrer">
              <img src={socialGithub} alt="navIcon" className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
            <a href="https://www.linkedin.com/in/aleksandrs-anackis-9831371a8/" target="_blank" rel="noopener noreferrer">
              <img src={socialLinkedIn} alt="navIcon"  className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
            <a href="https://www.instagram.com/alex_anackis_/" target="_blank" rel="noopener noreferrer">
              <img src={socialInstagram} alt="navIcon"  className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </a>
          </div>

          <div className={`navbar__divider ${delayedMenuOpen ? 'navbar__divider_visible' : ''}`}></div>

          <div className="navbar__lower-icons">
            <button onClick={() => {
              setCvModalOpen(true);
              setMenuOpened(false);
            }}>
              <img src={cvIcon} alt="Cv-icon" className={`navbar__wrapper_icons ${delayedMenuOpen ? 'navbar__wrapper_icons--visible' : ''}`}/>
            </button>
          </div>

          <div className={`navbar__email-mob ${delayedMenuOpen ? 'navbar__email-mob_visible' : ''}`}>
            <a href="tel:+37122101016">+37122101016</a>
            <a href="mailto:anackis@inbox.lv">anackis@inbox.lv</a>
          </div>

        </div>
      </div>
    </>
  )
}

const MenuButton = ({ label, onClick, isVisible, isActive  }) => {
  return (
    <button
      onClick={onClick}
      className={`navbar__link navbar__neonText ${isVisible ? "navbar__link--visible" : ""} ${isActive ? "active" : ""}`}
    >
      {label}
    </button>
  );
};
