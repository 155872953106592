import { Section } from "../section";
import ContactContent from "../contact-content/contact-content";

const ContactSection = ({ openProjectModal, currentSection }) => {
  const isModalOpen = openProjectModal;
  
  return (
    <Section id="connect__section">
      <ContactContent 
        currentSection={currentSection}
        closeModal={isModalOpen ? () => openProjectModal(false) : null}
      />
      {/* <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div> */}
    </Section>
  )
}

export default ContactSection
