// import { useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';

export function Room({ section, ...props }) {
  const { nodes, materials } = useGLTF('models/room.glb');
  // const [isVisible, setIsVisible] = useState(true); 

  // useEffect(() => {
  //   const updateVisibility = () => {
  //     const visible = section !== 2;
  //     setIsVisible(visible);
  //   };

  //   const timer = setTimeout(updateVisibility, 1000); 

  //   return () => clearTimeout(timer);
  // }, [section]); 

  // if (!isVisible) {
  //   return null; 
  // }

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
        <mesh geometry={nodes.Box010001_Color_0.geometry} material={materials.Color} />
        <mesh geometry={nodes.Box010001_mirror_0.geometry} material={materials.mirror} />
        <mesh geometry={nodes.Box010001_metal_0.geometry} material={materials.metal} />
        <mesh geometry={nodes.Box010001_metal001_0.geometry} material={materials['metal.001']} />
        <mesh geometry={nodes.Box010001_emission_window_0.geometry} material={materials.emission_window} />
        <mesh geometry={nodes.Box010001_emission_lamp_0.geometry} material={materials.emission_lamp} />
        <mesh geometry={nodes.Box010001_glass_0.geometry} material={materials.glass} />
      </group>
    </group>
  );
}

useGLTF.preload('models/room.glb');
