import { useEffect, useState } from 'react';
import { useSection } from '../../../contexts/SectionContext'; 
import logoImg from '../../../assets/icons/logo.webp';
import socialGithub from '../../../assets/socialIcons/github.svg';
import socialLinkedIn from '../../../assets/socialIcons/linkedin.svg';
import cvIcon from '../../../assets/socialIcons/cv-icon.svg';

export const Navbar = ({ menuOpened, setConnectModalOpen, setCvModalOpen }) => {
  const { section, setSection } = useSection();
  const [initialAnim, setInitialAnim] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialAnim(false); 
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <nav className={`nav ${initialAnim ? "nav-initial" : menuOpened ? "nav-hidden" : "nav-showed"}`}>
      <div className="nav-container">
        <div className="nav-container_left">
          <div className="nav-logo"  onClick={() => setSection(0)}>
            <img className='nav-logo-img' src={logoImg} alt="logo-img"/>
          </div>
          <div className="nav-links">
            {["Home", "About", "Skills", "Projects", "Contacts"].map((label, index) => (
              <div
                key={index}
                href={`#${label.toLowerCase()}`}
                className={section === index ? 'nav-links-link active' : 'nav-links-link'}
                onClick={() => setSection(index)}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
        <div className="nav-container_right">
          <div className="nav-contacts">
            <div>
              <a href="tel:+37122101016">+37122101016</a>
            </div>
            <div>
              <a href="mailto:anackis@inbox.lv">anackis@inbox.lv</a>
            </div>
          </div>
          <div className="nav-socials">
            <a className="nav-socials-button nav-socials-button_git" href="https://github.com/anackis" target="_blank" rel="noopener noreferrer">
              <img src={socialGithub} alt="GitHub"/>
            </a>
            <a className="nav-socials-button" href="https://www.linkedin.com/in/aleksandrs-anackis-9831371a8/" target="_blank" rel="noopener noreferrer">
              <img src={socialLinkedIn} alt="LinkedIn"/>
            </a>
            <button className="nav-socials-button nav-socials-button_cv" onClick={() => {setCvModalOpen(true)}}>
              <img src={cvIcon} alt="Instagram"/>
            </button>
            
          </div>
          <button className="nav-connect-btn" onClick={() => setConnectModalOpen(true)}>CONNECT NOW
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

