import { createContext, useState, useContext } from 'react';

const SectionContext = createContext({ section: 0, setSection: () => {} });

export const useSection = () => useContext(SectionContext);

export const SectionProvider = ({ children }) => {
  const [section, setSection] = useState(0);
  const value = { section, setSection };
  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
};
