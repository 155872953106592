import { motion } from 'framer-motion';

export const Section = (props) => {
  const {children, id} = props;

  return (
    <motion.section 
      id={id}
      className={`interface__section `}
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
      // initial={{
      //   opacity: 0,
      //   y: 100,
      // }}
      // whileInView={{
      //   opacity: 1,
      //   y: 0,
      //   transition: {
      //     duration: 1,
      //     delay: 0.6,
      //   }
      // }}
    >
      {children}
    </motion.section>
  )
} 