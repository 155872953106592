import { useState, useEffect  } from 'react';
import { Section } from '../section';
import { projects1, projects2 } from '../../../utils/projectsData';
import GithubIcon from '../../../assets/socialIcons/github.svg';
import InternetIcon from '../../../assets/socialIcons/internet-icon.svg';
import Slider from "react-slick";
import { debounce } from 'lodash';

const ProjectsSection = ({ openProjectModal, currentSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (currentSection === 3) {
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 1200);
    } else {
      setIsVisible(false);
    }

    return () => clearTimeout(timer);
  }, [currentSection]);

  return (
    <Section id="projects__section">
      <div className={`projects ${isVisible ? 'projects-visible' : 'projects-not-visible'}`}>
        <TabComponent 
          openProjectModal={openProjectModal}
          currentSection={currentSection}
        />
      </div>
    </Section>
  )
}

const TabComponent = ({ openProjectModal }) => {
  const [activeTab, setActiveTab] = useState('Tab1');
  const projects = activeTab === 'Tab1' ? projects1 : projects2;
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1399);

  const sliderSettings = {
    // centerMode: true,
    centerPadding: '0px',
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      // {
      //   breakpoint: 991,
      //   settings: {
      //     arrows: false,
      //   }
      // },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: false,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        }
      },
    ],
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsLargeScreen(window.innerWidth > 1399);
    }, 1000); 
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const renderProjects = () => {
    if (isLargeScreen) {
      // Full grid display logic for large screens
      return (
        // <div className={`tab-content`}>
          <div className={`projects__wrapper`}>
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                activeTab={activeTab}
                openProjectModal={openProjectModal}
              />
            ))}
          </div>
        // </div>
      );
    } else {
      // Carousel logic
      return (
        <>
          <div className={`tab-content`}>
            <Slider {...sliderSettings}>
              {projects.map((project, index) => (
                <div key={index}>
                  <ProjectCard
                    project={project}
                    activeTab={activeTab}
                    key={index}
                    openProjectModal={openProjectModal}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
      );
    }
  };

  return (
    <div className='projects__tab-component'>
      <div className="projects__button-wrapper">
        <div className="projects__button-wrapper_block">
          <div className="section__header"><div className='fz-header section__header_text'>Projects</div></div>
        </div>
        <div className='projects__button-wrapper_block'>
          <button
            className={`projects__tab-button projects__tab-button1 ${activeTab === 'Tab1' ? 'projects__tab-button_active1' : ''}`}
            onClick={() => handleTabChange('Tab1')}
          >
            <span>With Links</span>
          </button> 
          <button
            className={`projects__tab-button projects__tab-button2 ${activeTab === 'Tab2' ? 'projects__tab-button_active2' : ''}`}
            onClick={() => handleTabChange('Tab2')}
          >
            <span>Only GitHub</span>
          </button>
        </div>
        <div className='projects__button-wrapper_block'></div>
      </div>

      {renderProjects()}
    </div>
  );
};


const ProjectCard = ({ activeTab, project, openProjectModal }) => {
  return (
    <>
      <div className={activeTab === 'Tab1' ? "projects__card projects__card_pink" : "projects__card projects__card_blue"}>
        <div className={`projects__card-img-holder`}>
          <img 
            src={project.thumbnail} 
            alt={project.title} 
            className={`projects__card-img `}
            onClick={() => openProjectModal(project)}
          />
        </div>

        <span>
          <span className="projects__header-wrapper">
            <h3 className={activeTab === 'Tab1' ? "projects__card__header1" : "projects__card__header2"}>{project.title}</h3>
            <div className="projects__wrapper-for-links">
            {project.link && (
              <a 
                className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_internet" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_internet"} 
                href={project.link}
                target="_blank" rel="noopener noreferrer"
              >
                <img src={InternetIcon} alt="GitHub"/>
              </a>
            )}

            <a 
              className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_github" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_github"} 
              href={project.linkGitHub}
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img src={GithubIcon} alt="GitHub"/>
            </a>
          </div>
          </span>
          
          <p className='fz-text'>{project.description}</p>

          <span className='projects__hashtags'>
            {project.hashtags.map((hashtag, index) => (
              <div className={`hashtag_${index}`} key={index}>{hashtag}</div>
            ))}
          </span>

        </span>

      </div>
    </>
  );
};

export default ProjectsSection
